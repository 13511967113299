import { APP_INITIALIZER, enableProdMode, isDevMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter } from '@angular/router';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { provideTransloco } from '@jsverse/transloco';
import { TranslocoHttpLoader } from './infrastructure/transloco-loader';
import { AZURE_CONFIGURATION, TokenService } from './infrastructure/token.service';
import { AuthInterceptor } from './infrastructure/auth.interceptor';

if (environment.production) {
  enableProdMode();
}

const initializer = async () => {
  const azureConfiguration = await (await fetch(window.location.origin + '/azure-config')).json();
  bootstrapApplication(AppComponent, {
    providers: [
      {
        provide: AZURE_CONFIGURATION,
        useValue: azureConfiguration
      },
      {
        provide: APP_INITIALIZER,
        useFactory: initializeServices,
        deps: [TokenService],
        multi: true,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true,
      },
      { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
      provideIonicAngular(),
      provideRouter(routes),
      provideHttpClient(
        withInterceptorsFromDi()
      ),
      provideTransloco({
        config: {
          availableLangs: ['en', 'fr', 'nl'],
          defaultLang: 'en',
          reRenderOnLangChange: true,
          prodMode: !isDevMode(),
        },
        loader: TranslocoHttpLoader
      })
    ],
  });
};
initializer();

function initializeServices(tokenService: TokenService) {
  return async (): Promise<void> => {
    await tokenService.initialize();
  };
}