import { Component, inject, OnDestroy, ViewChild } from '@angular/core';
import { IonInput, IonSearchbar, IonItem, IonList, IonLabel, IonModal, IonIcon, IonContent, IonButtons, IonButton, IonTitle, IonToolbar, IonHeader } from '@ionic/angular/standalone';
import { close } from 'ionicons/icons';
import { addIcons } from 'ionicons';
import { MessageController } from 'src/infrastructure/message.controller';
import { CommonModule } from '@angular/common';
import { BarcodeService } from 'src/app/service/barcode.service';
import { Subscription } from 'rxjs';
import { BarcodeDetectorPolyfill } from '@undecaf/barcode-detector-polyfill';

@Component({
  selector: 'app-barcode-scanner',
  templateUrl: 'barcode-scanner.component.html',
  styleUrls: ['barcode-scanner.component.scss'],
  standalone: true,
  imports: [CommonModule, IonModal, IonLabel, IonList, IonItem, IonSearchbar, IonInput, IonIcon, IonContent, IonButtons, IonButton, IonTitle, IonToolbar, IonHeader],
})
export class BarcodeScannerComponent implements OnDestroy {

  @ViewChild('cameraView', { static: false }) cameraView: any;

  private messageController = inject(MessageController);
  private barcodeService = inject(BarcodeService);

  private requests: Array<(value: string | undefined) => void> = [];
  private subscription?: Subscription;

  private mediaStream?: MediaStream;
  private offscreenCanvas?: OffscreenCanvas;

  public isModalOpen = false;
  constructor() {
    addIcons({ close });
    this.subscription = this.barcodeService.requests$$.subscribe(request => {
      this.requests.push(request);
      this.beginScan();
    });
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  async beginScan() {
    try {
      this.isModalOpen = true;
      this.mediaStream = await navigator.mediaDevices.getUserMedia({ audio: false, video: { facingMode: 'environment' } });
      this.cameraView.nativeElement.srcObject = this.mediaStream;

      await this.scanImage();

    } catch (error: any) {
      await this.messageController.error(error?.message ?? error);
    }
  }

  getOffscreenCanvas() {
    if (this.offscreenCanvas)
      return this.offscreenCanvas;

    const width = 500;
    const height = 500;
    return new OffscreenCanvas(width, height);
  }

  private isScannig=false;
  async scanImage() {
    const canvas = this.getOffscreenCanvas();
    const context = canvas.getContext('2d');
    if (context === null)
      throw new Error();

    this.isScannig = true;
    let qrCodeValue:string | undefined = undefined;
    while (this.isScannig) {      
      context.drawImage(this.cameraView.nativeElement, 0, 0);
      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      const detector = new BarcodeDetectorPolyfill({ formats: ['qr_code'] });
      const symboles = await detector.detect(imageData);
      if(symboles.length > 0){  
        qrCodeValue = symboles[0].rawValue;
        break;        
      }
      await new Promise(r => setTimeout(r, 1000));      
    }
    
    this.requests.map(r => r(qrCodeValue));
    this.isModalOpen = false;
    this.cameraView.nativeElement.srcObject = null;
    if (this.mediaStream)
      for (const videoTrack of this.mediaStream.getVideoTracks())
        videoTrack.stop();
    this.requests = [];
  }


  closeModal() {
    this.isScannig = false;
  }

}

