import { Routes } from '@angular/router';
import { AuthGuardService } from 'src/infrastructure/auth-guard.service';

export const routes: Routes = [
  {
    path: 'signin-oidc',
    loadComponent: () => import('./page/signin-oidc/signin-oidc.page').then((m) => m.SigninOidcPage),
  },{
    path: 'home',
    canActivate: [AuthGuardService],
    loadComponent: () => import('./page/home/home.page').then((m) => m.HomePage),
    data: {
      roles:[]
    }
  },{
    path: 'store-in-out',
    canActivate: [AuthGuardService],
    loadComponent: () => import('./page/store-in-out/store-in-out.page').then((m) => m.StoreInOutPage),
    data: {
      roles:[]
    }
  },{
    path: 'user-list',
    canActivate: [AuthGuardService],
    loadComponent: () => import('./page/user/user-list.page').then((m) => m.UserListPage),
    data: {
      roles:[]
    }
  },{
    path: 'material-list',
    canActivate: [AuthGuardService],
    loadComponent: () => import('./page/material/material-list.page').then((m) => m.MaterialListPage),
    data: {
      roles:[]
    }
  },{
    path: 'stock-list',
    canActivate: [AuthGuardService],
    loadComponent: () => import('./page/stock/stock-list.page').then((m) => m.StockListPage),
    data: {
      roles:[]
    }
  },{
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
];
