
<div [ngClass]="'popup' +  (isModalOpen ? ' show' : '')">    
    <ion-header>
        <ion-toolbar>
            <ion-buttons slot="end">
                <ion-button (click)="closeModal()"><ion-icon name="close"></ion-icon></ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
    <video style="width:500px; height:500px" width="500" height="500" #cameraView muted autoplay playsinline></video>
</div>

