import { Component } from '@angular/core';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';
import { BarcodeScannerComponent } from './component/barcode-scanner/barcode-scanner.component';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [IonApp, IonRouterOutlet,BarcodeScannerComponent ],
})
export class AppComponent {  
  constructor() {}
}
