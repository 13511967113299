import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';


@Injectable({ providedIn: 'root' })
export class AuthGuardService {
  private readonly authService = inject(AuthService);
  private readonly router = inject(Router);

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    if (!this.authService.isAuthenticated) {
      await this.router.navigate(['/signin-oidc'], { queryParams: { ...route.queryParams, refer: location.pathname } });
      return false;
    }

    const roles = route.data['roles'] as string[] | undefined;
    if (!roles || roles.length <= 0) return true;

    return this.authService.isOneOfRoles(roles);
  }
}
